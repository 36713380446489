<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div class="col-12">
              <b-button
                size="sm"
                class="mb-2"
                @click="modalShow = true"
                id="addButton"
              >
                <b-icon icon="plus" aria-hidden="true"></b-icon>
                Añadir
              </b-button>
            </div>
            <div class="col-12">
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                sticky-header
              >
                <template v-slot:cell(enter_price)="data">
                  <b-form-input
                    v-model="data.item.price"
                    @input="bufferPrice = data.item.price"
                    @keydown.enter.prevent="savePrice(data.item.id)"
                  />
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>

    <b-modal v-model="modalShow" title="Agregar item">
      <b-form-group label="Seleccione item:">
        <a-multiselect
          id="ajax"
          placeholder="Selecciona un item"
          v-model="selectedItem.item"
          :options="selectItems"
          track-by="id"
          label="description"
          :internal-search="false"
          @search-change="fetchItems"
          :loading="isLoading"
          :clear-on-select="true"
          :close-on-select="true"
          ref="selectedItem"
          @select="inputItem"
        >
        </a-multiselect>
      </b-form-group>
      <b-form-group label="Cantidad">
        <b-form-input
          v-model="selectedItem.quantity"
          placeholder="Cantidad"
          ref="selectedQuantity"
          @keydown.enter.prevent="inputQuantity"
        ></b-form-input>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="saveItem()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  data() {
    return {
      bufferPrice: 0.0,
      isLoading: true,
      selectItems: [],
      selectedItem: { item: {}, quantity: 1 },
      buttonColor: null,
      colors: [],
      form: {},
      modalShow: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "item.description",
          sortable: true,
          label: "Item"
        },
        {
          key: "quantity",
          label: "Cantidad"
        },
        { key: "enter_price", label: "Ingrese precio" }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    savePrice(item_id) {
      console.log(
        "Actualizando item con id y precio buffer: ",
        item_id,
        this.bufferPrice
      );
      ApiService.post(`api/quote-request-detail/${item_id}/price/update/`, {
        price: this.bufferPrice
      }).then((res) => {
        console.log(res);
        this.$bvToast.toast("COSTO ACTUALIZADO", {
          title: `Éxito`,
          variant: "success",
          solid: true
        });
      });
    },
    inputQuantity() {
      console.log("Los refs son ", this.$refs);
      this.items.unshift({ ...this.selectedItem });

      // SAVE THE ITEM IMMEDIATELY
      this.form = {
        quantity: this.selectedItem.quantity,
        quote_request: this.$route.params.id,
        item: this.selectedItem.item.id
      };
      console.log("El formulario es: ", this.form);
      this.saveItem();

      this.$refs.selectedItem.$el.focus();
    },
    inputItem() {
      console.log("ITEM ELEGIDO: ", this.selectedItem);
      this.$refs.selectedQuantity.select();
    },
    fetchItems(query) {
      this.isLoading = true;
      if (query.length < 4) return false;
      ApiService.get(`api/item/search`, `?q=${escape(query)}`).then(
        (response) => {
          this.selectItems = response.data;
          this.isLoading = false;
        }
      );
    },
    saveItem() {
      console.log("El form a guardar es: ", this.form);
      ApiService.post("api/quote-request-detail/", this.form)
        .then(() => {
          this.$bvToast.toast("Se ha guardado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          this.listItems();
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    },
    listItems() {
      ApiService.get(`api/quote-request/${this.$route.params.id}/details`).then(
        (response) => {
          this.items = response.data;
        }
      );
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Solicitud de Cotización" },
      { title: "Añadir items" }
    ]);
  }
};
</script>
